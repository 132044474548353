define("discourse/plugins/discourse-custom-topic-lists/discourse/templates/list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{body-class (concat-class "custom-list-body" this.model.path)}}
  <Discovery::Layout @model={{this.model}}>
    <:navigation>
      <section class="navigation-container">
        <DNavigation @model={{@model}} />
      </section>
  
    </:navigation>
    <:list>
      <Discovery::Topics
        @model={{this.model}}
        @canBulkSelect={{this.canBulkSelect}}
        @bulkSelectHelper={{this.bulkSelectHelper}}
        @showDismissRead={{this.showDismissRead}}
        @dismissRead={{this.dismissRead}}
      />
    </:list>
  </Discovery::Layout>
  */
  {
    "id": "T/34LhYZ",
    "block": "[[[1,[28,[35,0],[[28,[37,1],[\"custom-list-body\",[30,0,[\"model\",\"path\"]]],null]],null]],[1,\"\\n\"],[8,[39,2],null,[[\"@model\"],[[30,0,[\"model\"]]]],[[\"navigation\",\"list\"],[[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"navigation-container\"],[12],[1,\"\\n      \"],[8,[39,5],null,[[\"@model\"],[[30,1]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,7],null,[[\"@model\",\"@canBulkSelect\",\"@bulkSelectHelper\",\"@showDismissRead\",\"@dismissRead\"],[[30,0,[\"model\"]],[30,0,[\"canBulkSelect\"]],[30,0,[\"bulkSelectHelper\"]],[30,0,[\"showDismissRead\"]],[30,0,[\"dismissRead\"]]]],null],[1,\"\\n  \"]],[]]]]]],[\"@model\"],false,[\"body-class\",\"concat-class\",\"discovery/layout\",\":navigation\",\"section\",\"d-navigation\",\":list\",\"discovery/topics\"]]",
    "moduleName": "discourse/plugins/discourse-custom-topic-lists/discourse/templates/list.hbs",
    "isStrictMode": false
  });
});